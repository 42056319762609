import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TopnavComponent } from './topnav/topnav.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ColorSwitcherComponent } from './color-switcher/color-switcher.component';
import { FooterComponent } from './footer/footer.component';
import { HeadingComponent } from './heading/heading.component';
import { ApplicationMenuComponent } from './application-menu/application-menu.component';
import { FormsModule } from '@angular/forms';
import {ClickStopPropagationDirective} from '../../directives/click-stop-propagation.directive';
import {TrimPipe} from '../../pipes/trim.pipe';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {RightbarComponent} from './rightbar/rightbar.component';
import {ToTimePipe} from '../../pipes/to-time.pipe';
import {ComponentsStateButtonModule} from '../../components/state-button/components.state-button.module';
import {RiskFileComponent} from '../../components/risk-file/risk-file.component';
import {HeadingActionsComponent} from './heading-actions/heading-actions.component';
import {ModalTitleComponent} from '../../components/modal-title/modal-title.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {TipoBiaPipe} from '../../pipes/tipo-bia.pipe';
import {TipoBiaService} from '../../services/tipo-bia.service';

@NgModule({
  declarations: [
    TopnavComponent,
    RightbarComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    FooterComponent,
    HeadingComponent,
    ApplicationMenuComponent,
    ClickStopPropagationDirective,
    TrimPipe,
    ToTimePipe,
    TipoBiaPipe,
    RiskFileComponent,
    HeadingActionsComponent,
    ModalTitleComponent,
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    TranslateModule,
    RouterModule,
    CollapseModule.forRoot(),
    FormsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    SimpleNotificationsModule,
    ComponentsStateButtonModule,
    ButtonsModule.forRoot(),
  ],
  exports: [
    TopnavComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    FooterComponent,
    HeadingComponent,
    ApplicationMenuComponent,
    TrimPipe,
    RightbarComponent,
    ClickStopPropagationDirective,
    RiskFileComponent,
    HeadingActionsComponent,
    ModalTitleComponent,
    TipoBiaPipe,
  ]
})
export class LayoutContainersModule { }
